import { URL } from "@/api/company";
import { URL as GOV_URL } from "@/api/gov_invoices_history.js";
import { COMPANY_ADD_USER } from "@/api/users";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { nextTick, ref } from "vue";
import { Form } from "vee-validate";
import * as yup from "yup";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import InputMoney from "@/components/inputMoney.vue";
import UserIcon from "@/components/UserIcon.vue";
import DVHCSelect from "@/components/DVHCSelect.vue";
import { checkTaxCode, checkTaxCodeRule } from "@/plugins/rules.js";
import searchInput from "@/components/searchInput";
import paginate from "@/components/paginate.vue";
import formatDate, {formatDateStandard}  from "@/plugins/formatDate.js";
import { MSTTCGP_TEXT, LOAIDOANHNGHIEP_OPTIONS } from "../../../consts";
import nganhnghe_options from "@/assets/data/nganhnghe.json";

export default {
  components: {
    Form,
    InputWithValidation,
    InputMoney,
    searchInput,
    paginate,
    UserIcon,
    DVHCSelect,
  },
  data() {
    return {
      tabDetailCompany: "EditCompany",
      showConfirmDeleteModal: false,
      showAddModal: false,
      showEditModal: false,
      targetCompany: {
        deductibleValue: 0,
      },
      editTargetCompany: {},
      companies: [],
      schema: {},
      search: "",
      paginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      dialogWidth: "30%",
      dialogWidthEdit: "50%",
      arrYear: [],
      isErrorVat22: false,
      isErrorVat22Edit: false,
      isDeactive: false,
      targetAddUser: {
        company_id: null,
        email: "",
      },
      selectCompanySuperAdmin: null,
      listAllCompanySuperAdmin: [],
      listAllCompanySuperAdminSelect: [],
      listCompanyUser: [],
      isAddUser: false,
      showAddUserModal: false,
      rowSelected: null,
      isLoading: false,
      resetPasswordModal: false,
      ngayCap: "",
      latestData: {},

      advanceSearchVisible: false,
      advancedSearch: {
        city: null,
        district: null,
        ward: null,
        chuSoHuu: null,
        ngayCap: null,
        ngayDongMST: null,
        loaiDoanhNghiep: null,
        maNganhNghe: null,
        haveGovPassword: false,
      },

      showGovPassword: false,
      resetHVHC: false,
      loaiDoanhNghiep_options: LOAIDOANHNGHIEP_OPTIONS,
      nganhnghe_options: nganhnghe_options,
    };
  },
  setup() {
    yup.addMethod(yup.string, "taxCode", checkTaxCodeRule);

    const schema = yup.object({
      email: yup
        .string()
        .email(`Email không đúng định dạng`)
        .nullable()
        .label("Email address"),
      // tenDoanhNghiep: yup
      //   .string()
      //   .required("Tên doanh nghiệp là một trường bắt buộc")
      //   .typeError("Tên doanh nghiệp là một trường bắt buộc"),
      // webSite: yup.string().nullable().label("webSite"),
      // giamDoc: yup.string().nullable().label("Giám đốc"),
      // chuSoHuu: yup
      //   .string()
      //   .required("Đại diện pháp luật là một trường bắt buộc")
      //   .typeError("Đại diện pháp luật là một trường bắt buộc")
      //   .label("Đại diện pháp luật"),
      // tenGiaoDich: yup.string().nullable().label("Tên giao dịch"),
      // diaChiDangKyQuanLy: yup
      //   .string()
      //   .nullable()
      //   .label("Địa chỉ đăng ký quản lý"),
      // diaChiTruSo: yup.string().nullable().label("Địa chỉ trụ sở"),
      // diaChiChuSoHuu: yup.string().nullable().label("Địa chỉ chủ sở hữu"),
      // fax: yup.string().nullable().label("Fax"),
      // startYear: yup.string().nullable(),
      // ngayCap: yup.string().nullable().label("Ngày cấp").test("", "", function(value) {
      //   const { startYear } = this.parent
      //   const { path, createError } = this;
      //   return (value && (new Date(value)).getFullYear() <= parseInt(startYear)) || createError({ path, message: "Ngày nhập là bắt buộc và trước năm bắt đầu kê khai" });
      // }),
    });
    const mst = yup.object({
      mst: yup
        .string()
        .taxCode("Mã số thuế không hợp lệ")
        .required("Mã số thuế là một trường bắt buộc"),
    });
    const validateAddUser = yup.object({
      emailQuanLyTaiKhoan: yup
        .string()
        .required()
        .email("Email không đúng định dạng")
        .required("Email là một trường bắt buộc"),
    });
    const reset = ref(null);
    const resetAddMst = ref(null);
    const resetAddUser = ref(null);
    return {
      reset,
      resetAddMst,
      resetAddUser,
      schema,
      mst,
      validateAddUser,
    };
  },

  created() {
    const d = new Date();
    const m = d.getFullYear();
    for (let index = 0; index < 5; index++) {
      this.arrYear.push(m - index);
    }
    this.search = this.getCompanySearch;
    // Check role == itax
    if (this.getUserIndexRole >= 5) {
      this.getAllCompanySuperAdmin();
    }
    this.paginate = this.getCompanyPaginate;
    this.fetchCompanies();
    this.setDialogWidth();
  },

  computed: {
    ...mapGetters([
      "getCompanySearch",
      "getUserIndexRole",
      "getCompanyPaginate",
      "getTargetCompany",
      "getAuthUser",
    ]),
    role() {
      return this.$store.state.setRoleUser.role;
    },
    vat_22() {
      return this.targetCompany.deductibleValue;
    },
    vat_22_edit() {
      return this.editTargetCompany.vat_22;
    },
    advancedSearchKeys() {
      const text_mapping = {
        city: 'Địa chỉ trụ sở',
        chuSoHuu: 'Đại diện pháp luật',
        ngayCap: 'Ngày cấp',
        ngayDongMST: 'Ngày đóng',
        loaiDoanhNghiep: 'Loại doanh nghiệp',
        maNganhNghe: 'Ngành nghề',
        haveGovPassword: 'Mật khẩu GOV',
      };
      return Object.keys(this.advancedSearch).filter(
        item => !!this.advancedSearch[item] && text_mapping[item]
        ).map(item => {
          return {
            key: item,
            text: text_mapping[item],
          };
        })
    },
    isPremiumUser() {
      return (
        this.getAuthUser.userType == "ITaxAdmin" ||
        this.getAuthUser.userType == "SaleAdmin" ||
        this.getAuthUser.plan == "premium"
      );
    },
    isSearchValidTaxcode() {
      return checkTaxCode(this.search);
    }
  },

  methods: {
    ...mapActions(["loadingLayer"]),

    async getDetailCompany(id_company) {
      const result = await this.$request({
        url: URL.EDIT_DETAIL_COMPANY.replace(":id", id_company),
      });
      const res = result.data;
      if (res.code == 200) {
        return res.data;
        // this.clickRow(res.data);
      }
    },

    async updateTargetCompany() {
      const result = await this.$request({
        url: URL.EDIT_DETAIL_COMPANY.replace(":id", this.getTargetCompany.id),
      });
      const res = result.data;
      if (res.code == 200) {
        const { id, start_year, tenDoanhNghiep, kieuKeKhai } = res.data;
        this.$store.dispatch("setTargetCompany", res.data);
        this.$store.dispatch("setCompaniesSelected", id);
        this.$store.dispatch("setStartYear", start_year);
        this.$store.dispatch("setCompanyName", tenDoanhNghiep);
        this.$store.dispatch("setDeclarationType", kieuKeKhai);
      }
    },

    resetValidate() {
      this.editTargetCompany = {};
      this.reset.resetForm();
    },

    resetFormAddMst() {
      this.resetAddMst.resetForm();
    },

    closeModalEdit() {
      this.tabDetailCompany = "EditCompany";
      this.selectCompanySuperAdmin = null;
      this.arrYear = [];
      this.showEditModal = false;
      const d = new Date();
      const m = d.getFullYear();
      for (let index = 0; index < 5; index++) {
        this.arrYear.push(m - index);
      }
    },

    async fetchCompanies() {
      const param = {
        have_gov_password: this.advancedSearch.haveGovPassword,
        page: this.paginate.currentPage,
        limit: this.paginate.limit,

      };
      if (this.search) param.key_search = this.search;
      if (this.advancedSearch.city) param.city = this.advancedSearch.city;
      if (this.advancedSearch.district) param.district = this.advancedSearch.district;
      if (this.advancedSearch.ward) param.ward = this.advancedSearch.ward;
      if (this.advancedSearch.chuSoHuu) param.chuSoHuu = this.advancedSearch.chuSoHuu;
      if (this.advancedSearch.ngayCap) param.ngayCap = this.advancedSearch.ngayCap.map(item => formatDateStandard(item)).join(',');
      if (this.advancedSearch.ngayDongMST) param.ngayDongMST = this.advancedSearch.ngayDongMST.map(item => formatDateStandard(item)).join(',');
      if (this.advancedSearch.loaiDoanhNghiep) param.loaiDoanhNghiep = this.advancedSearch.loaiDoanhNghiep;
      if (this.advancedSearch.maNganhNghe) param.maNganhNghe = this.advancedSearch.maNganhNghe.split('-')[0].trim();

      this.showGovPassword = this.advancedSearch.haveGovPassword;
      this.isLoading = true;
      const result = await this.$request({
        url: URL.LIST_COMPANY,
        params: param,
      });
      this.isLoading = false;

      const res = result.data;

      if (res.code == 200 && res.success) {
        this.companies = res.data.data;

        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
        this.$store.dispatch("setCompanyPaginate", this.paginate);

        if (res.data.data.length == 0) {
          this.$store.dispatch("setCompaniesSelected", null);
        }
      }

    },

    async getAllCompanySuperAdmin(keyword) {
      const params = {keyword}
      const result = await this.$request({
        url: URL.ALL_COMPANY_SUPER_ADMIN,
        params
      });
      const res = result.data;
      if (res.code == 200) {
        this.listAllCompanySuperAdmin = res.data;
        this.handleCompanySuperAdmin()
      }
    },

    handleCompanySuperAdmin() {
      const selectedAdmin = this.latestData;
      this.listAllCompanySuperAdminSelect = [...this.listAllCompanySuperAdmin]
      if (selectedAdmin && selectedAdmin.companySuperAdminDetail && selectedAdmin.companySuperAdminDetail.id) {
        this.selectCompanySuperAdmin = selectedAdmin.companySuperAdminDetail.user.emailQuanLyTaiKhoan;
        this.listAllCompanySuperAdminSelect = this.listAllCompanySuperAdminSelect.filter(item => item.id != selectedAdmin.companySuperAdminDetail.id)
        this.listAllCompanySuperAdminSelect.unshift({
          id: selectedAdmin.companySuperAdminDetail.id,
          user__emailQuanLyTaiKhoan: selectedAdmin.companySuperAdminDetail.user.emailQuanLyTaiKhoan,
        })
      }
    },

    handleSelectCompanySuperAdminBeforeSubmitUpdate(user) {
      this.listAllCompanySuperAdmin.forEach((item) => {
        if (user === item.user__emailQuanLyTaiKhoan) {
          return item.id;
        }
      });
    },

    openCreateModal(mst=null) {
      const d = new Date();
      this.showAddModal = true;
      this.targetCompany = {};
      if (mst) {
        this.targetCompany.mst = mst
      }
      this.targetCompany.startYear = d.getFullYear();
      this.targetCompany.declaration = "Quý";
      this.isErrorVat22 = false;
    },

    openEditModal(data) {
      this.advanceSearchVisible = false;
      this.isErrorVat22Edit = false;
      this.showEditModal = true;
      this.editTargetCompany = _.cloneDeep(data);
      this.ngayCap = "";
      if (this.editTargetCompany.ngayCap) {
        this.ngayCap = formatDate(this.editTargetCompany.ngayCap)
      }
      if (this.editTargetCompany.ngayDongMST) {
        this.editTargetCompany.ngayDongMST = formatDate(this.editTargetCompany.ngayDongMST)
      }

      this.editTargetCompany.msttcgp_text = ""
      if (this.editTargetCompany.msttcgp && MSTTCGP_TEXT[this.editTargetCompany.msttcgp]) {
        this.editTargetCompany.msttcgp_text = `${MSTTCGP_TEXT[this.editTargetCompany.msttcgp]} - ${this.editTargetCompany.msttcgp}`
      }

      let y = new Date(this.editTargetCompany.ngayCap);
      this.arrYear = this.arrYear.filter((year) => {
        return year >= y.getFullYear();
      });
    },

    // Thêm người dùng
    addUser(id_company) {
      this.targetAddUser.company_id = id_company;
      this.isAddUser = true;
      this.showAddUserModal = true;
    },

    cancelModalAddUser() {
      this.showAddUserModal = false;
      this.isAddUser = false;
      this.targetAddUser = {
        email: "",
        company_id: null,
      };
      this.resetAddUser.resetForm();
    },

    // Mời người dùng
    async confirmInviteUser() {
      const data = {
        company_id: [this.targetAddUser.company_id],
        emailQuanLyTaiKhoan: this.targetAddUser.email,
        user_type: "CompanySuperAdminSub",
        force_exists: false,
      };
      const result = await this.$request({
        url: COMPANY_ADD_USER,
        method: "POST",
        data,
      });
      const res = result.data;

      if (res.code == 200) {
        this.$swal({
          text: "Thêm thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
        this.showAddUserModal = false;
        this.clickRow({'id': this.targetAddUser.company_id});
        this.fetchCompanies();
      }
      if (res.code == 400) {
        this.$swal({
          text: res.messages,
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },

    // Xóa người dùng
    async deleteUser(user_id) {
      this.$swal({
        title: "Xóa người dùng?",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            company_id: this.rowSelected,
            user_id: user_id,
          };
          const result = await this.$request({
            url: URL.DELETE_USER,
            method: "POST",
            data,
          });
          const res = result.data;
          if (res.code == 200) {
            this.$swal({
              text: res.messages,
              icon: "success",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
            this.clickRow({'id': this.rowSelected});
            this.fetchCompanies();
          } else {
            this.$swal({
              text: res.messages,
              icon: "error",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
          }
        }
      });
    },

    async clickRow(obj) {
      this.rowSelected = obj.id;
      obj = await this.getDetailCompany(obj.id)
      this.latestData = obj;
      this.handleCompanySuperAdmin();
      this.listCompanyUser = [];

      if (obj.companySuperAdminDetail.id) {
        let companySuperAdmin = obj.companySuperAdminDetail;
        this.listCompanyUser = [
          ...this.listCompanyUser,
          companySuperAdmin.user,
        ];
      }

      let companySuperAdminSub = obj.companySuperAdminSub;
      companySuperAdminSub.forEach((admin_sub) => {
        this.listCompanyUser = [...this.listCompanyUser, admin_sub.user];
      });

      let companyAdmin = obj.companyAdmin;
      companyAdmin.forEach((admin) => {
        this.listCompanyUser = [...this.listCompanyUser, admin.user];
      });

      let companyUser = obj.companyUser;
      companyUser.forEach(
        (user) => (this.listCompanyUser = [...this.listCompanyUser, user.user])
      );
      if (!this.isDeactive && !this.isAddUser && !this.resetPasswordModal) {
        this.openEditModal(obj);
      }
    },

    searchInput() {
      this.paginate.totalPage = 1;
      this.fetchCompanies();
    },

    focusInput() {
      const input = document.getElementById("input-search");
      input.focus();
      input.select();
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
      this.fetchCompanies();
    },

    handleLimitUpdate(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.fetchCompanies();
    },

    async addCompany() {
      if (!this.isErrorVat22) {
        this.loadingLayer(true);
        const data = {};
        data.company_tax_code = this.targetCompany.mst;
        data.start_year = this.targetCompany.startYear;
        data.vat_22 = this.targetCompany.deductibleValue;
        if (!this.targetCompany.deductibleValue) {
          data.vat_22 = 0;
        }
        if (this.targetCompany.declaration == "Quý") data.kieu_ke_khai = "Q";
        else data.kieu_ke_khai = "T";

        const result = await this.$request({
          url: URL.ADD_COMPANY,
          method: "POST",
          data,
        });
        const res = result.data;

        if (res.code == 200 && res.success) {
          this.$swal({
            text: "Tạo thành công",
            icon: "success",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
          this.fetchCompanies();
          this.showAddModal = false;
        }

        if (res.code == 400) {
          this.$swal({
            text: res.errors[0].company_tax_code,
            icon: "error",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
        }
        this.loadingLayer(false);
      }
    },

    async editCompany() {
      if (!this.isErrorVat22Edit) {
        this.loadingLayer(true);

        const data = _.cloneDeep(this.editTargetCompany);
        delete data.ngayCap
        delete data.ngayDongMST

        this.listAllCompanySuperAdmin.forEach((item) => {
          if (this.selectCompanySuperAdmin === item.user__emailQuanLyTaiKhoan) {
            data.companySuperAdmin = item.id;
          }
        });
        const result = await this.$request({
          url: URL.EDIT_DETAIL_COMPANY.replace(":id", data.id),
          method: "PUT",
          data,
        });
        const res = result.data;

        if (res.code == 200 && res.success) {
          this.$swal({
            text: "Cập nhật thành công",
            icon: "success",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
          this.fetchCompanies();
          if (this.getTargetCompany.id == this.editTargetCompany.id) {
            this.updateTargetCompany();
          }
          this.showEditModal = false;
          this.$store.dispatch("setStartYear", res.data.start_year);
        }

        if (res.code == 400) {
          this.$swal({
            text: Object.values(res.errors[0]),
            icon: "error",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
        }
        this.loadingLayer(false);
      }
    },

    statusCompany(value, id) {
      this.isDeactive = true;
      this.$swal({
        title: "Thay đổi trạng thái?",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (value == "Active") {
            value = "Deactivate";
          } else value = "Active";

          const data = {};
          data.tinhTrang = value;

          const res = await this.$request({
            url: URL.EDIT_DETAIL_COMPANY.replace(":id", id),
            method: "PUT",
            data,
          });

          if (res.data.code == 200 && res.data.success) {
            this.$swal({
              text: "Cập nhật thành công",
              icon: "success",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
            this.fetchCompanies();
          }
        }
        this.isDeactive = false;
      });
    },

    updateCompanyStatus() {
      this.$swal({
        title: "Cập nhật thông tin công ty",
        html: "<small><i>*Tác vụ có thể cần thời gian xử lý</i></small>",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await this.$request({
            url: URL.LOOKUP_DETAIL_COMPANY.replace(
              ":id", this.rowSelected
            ),
            method: "POST"
          });

          if (res.data.code == 200 && res.data.success) {
              await this.fetchCompanies();
              this.clickRow(res.data.data)
          }
        }
      });
    },

    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    setDialogWidth() {
      let windowSize = document.body.clientWidth;

      if (windowSize < 1315 && windowSize >= 767) {
        this.dialogWidth = "40%";
      } else if (windowSize < 767) {
        this.dialogWidth = "90%";
        this.dialogWidthEdit = "90%";
      } else {
        this.dialogWidth = "30%";
        this.dialogWidthEdit = "50%";
      }
    },

    caculateRemainUsers(users=[]) {
      let NUMBER_USERS_DISPLAY = 3;
      if (this.getUserIndexRole < 3) {
        NUMBER_USERS_DISPLAY = 5;
      }
      let remain = users.length - NUMBER_USERS_DISPLAY - 1;
      return [NUMBER_USERS_DISPLAY, remain <= 0 ? 0 : remain];
    },

    isMainClass({ row }) {
      return row.is_main ? "font-bold" : "";
    },
    ableToRenewPassword(expired_at) {
      if (expired_at) {
        let currentTime = new Date();
        // Reset pass 2 weeks before expired
        currentTime.setDate(currentTime.getDate() + 30)
        return new Date(expired_at) < currentTime
      }

      return false
    },
    async renew_password() {
      this.resetPasswordModal = true
      this.$swal({
        title: "Gửi yêu cầu gia hạn mật khẩu",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { company_id: this.rowSelected };
          await this.$request({
            method: "PUT",
            url: GOV_URL.SYNC_GOV_INVOICES_HISTORY,
            isHideLoading: true,
            data,
          });
          await this.fetchCompanies();
        }
        this.resetPasswordModal = false
      })
    },

    resetSearchCondition () {
      this.resetHVHC = !this.resetHVHC; // force changes

      this.advancedSearch = {
        city: null,
        district: null,
        ward: null,
        chuSoHuu: null,
        ngayCap: null,
        ngayDongMST: null,
        loaiDoanhNghiep: null,
        maNganhNghe: null,
        haveGovPassword: false,
      }

      nextTick(() => {
        this.fetchCompanies();
      })
    },

    async handleRemoveLabelSearch (key) {
      this.paginate.totalPage=1;
      this.advancedSearch[key] = null;

      if (key == 'haveGovPassword') {
        this.advancedSearch[key] = false;
      }
      if (['city', 'district', 'ward'].indexOf(key) != -1) {
        this.resetHVHC = !this.resetHVHC; // force changes
        this.advancedSearch['city'] = null;
        this.advancedSearch['district'] = null;
        this.advancedSearch['ward'] = null;
      }

      nextTick(() => {
        this.fetchCompanies();
      })
    },

    searchLabelTransform (key) {
      if (key == 'haveGovPassword') {
        return "Mở"
      }

      if (['city', 'district', 'ward'].indexOf(key) != -1) {
        let result = this.advancedSearch['city'];
        if (this.advancedSearch['district']) {
          result += ", " + this.advancedSearch['district'];
        }
        if (this.advancedSearch['ward']) {
          result += ", " + this.advancedSearch['ward'];
        }
        return result;
      }

      if (key == 'ngayCap' || key == 'ngayDongMST') {
        return this.advancedSearch[key].map(item => formatDate(item)).join(' ~ ');
      }

      return this.advancedSearch[key];
    },

    formatDate,
    checkTaxCode,
    formatDateStandard,
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  watch: {
    vat_22(newVal) {
      if (newVal) {
        this.isErrorVat22 = newVal.length > 18;
      }
    },
    vat_22_edit(newVal) {
      if (newVal) {
        this.isErrorVat22Edit = newVal.length > 18;
      }
    },
    getCompanySearch(newVal) {
      this.search = newVal
      this.searchInput();
    }
  },
};
